<script>


import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import Stat from "@/components/widgets/stat.vue";


import TerminateDialog from './components/terminate_dialog.vue'

import TransferBalanceDialog from './components/transfer_balance_dialog.vue'
import UpdateRentDialog from './components/update_rent_dialog.vue'
import UpdateCommissionDialog from './components/update_commission_dialog.vue'
import ExportDialog from './components/export_dialog.vue'
import RTABalanceTransferDialog from './components/transfer_balance.vue'

import { getPMApi } from '@/api/pm'
import { financial, autoComplete } from '@/api/misc'


//import Choices from "choices.js";

/**
 * User list component
 */
export default {
  page: {
    title: "RTA List",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      pmList: [],
      title: "RTA List",
      items: [
        {
          text: "PM",
          href: "/",
        },
        {
          text: "RTA List",
          active: true,
        },
      ],
      current_rent: {
        details: []
      },
      search_str: '',
      searched_data: [],
      totalRows: 0,
      currentPage: 1,
      perPage: 24,
      current_pm: {},
      current_tenant: {},
      tenant_list: [],
      tenant_flag: 'Add',
      pageOptions: [10, 25, 50, 100],
      filter: '',
      filterOn: ["pm_id", "address"],
      sortBy: "value",
      sortDesc: false,
      start_page: 1,
      rta_query_type: '',
      rta_query_str: '',
      pm_status    : '',
      fields: [

        {
          key: "RTACode",
          sortable: true,
          tdClass: 'align-middle'
        },
        {
          key: "PropertyManager",
          sortable: true,
          tdClass: 'align-middle'
        },
        {
          key: "Address",
          sortable: true,
          tdClass: 'align-middle'
        },

        {
          key: "MonthlyRent",
          sortable: false,
          tdClass: 'align-middle'
        },
        {
          key: "Balance",
          sortable: false,
          tdClass: 'align-middle'
        },

        { key: "Action" }

      ],


      widgetData: [
        {
          icon: "uil-dollar-alt",
          title: "Balance Total",
          value: '$2123.34',
        },
        {
          icon: "uil-calculator",
          title: "Montly Rent Total",
          value: '$10234.3',
        },
        {
          icon: "uil-layer-group",
          title: "RTA List",
          value: this.rows,
        },

      ],

    };
  },
  components: {
    Layout,
    PageHeader,
    VueBootstrapTypeahead,
    Stat,


    TerminateDialog,


    TransferBalanceDialog,

    UpdateRentDialog,
    UpdateCommissionDialog,
    ExportDialog,
    RTABalanceTransferDialog
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.totalRows
    },
  },

  created() {

  },
  mounted() {
    this.queryList()
  },

  methods: {

    f(v, d = 2) {
      return financial(v, d)
    },

    queryList() {
      let data = {
        page: { page: this.currentPage, page_size: this.perPage },
        with_total_balance: true,
        with_monthly_rent: true
      }

      if (this.rta_query_type == 'AGENT') {
        data.agent_id = this.rta_query_str
      } else if (this.rta_query_type == 'PM') {
        data.property_code = this.rta_query_str
      }
      //data.status = 'Active'

      if (this.pm_status) {
        data.pm_status = this.pm_status
      }

      this.pmList = []
      getPMApi().list(data).then((res) => {
        res.data.map((o) => {
          o.pm_id = o.property_id
          o.checked = false
          this.pmList.push(o)
        })
        this.totalRows = res.page.total;
        this.widgetData[this.widgetData.length - 1].value = this.totalRows
        this.widgetData[0].value = '$' + financial(res.extra.total_balance, 2).toLocaleString()
        this.widgetData[1].value = '$' + financial(res.extra.total_monthly_rent, 2).toLocaleString()

        // this.append_rest_rta_list()

      })



    },



    pageChange(p) {
      this.currentPage = p
      this.queryList()
    },




    showAdjustmentBalanceDialog(pm) {
      this.current_rent = {
        property_id: pm.pm_id,
        available_balance: pm.balance_available,
        deposit: pm.deposit,
        balance_in_trust: pm.balance_in_trust,
        owner_reserved: pm.owner_reserved,
        rent_in_hold: pm.rent_in_hold ? pm.rent_in_hold : 0,

      }
      this.$bvModal.show('transfer_balance_dialog')
    },

    onBalanceAdjusted(data) {
      let p = this.pmList.find(e => e.property_id == data.pm_id)
      if (p) {
        p.balance_available = data.new_balance
        p.rent_in_hold = data.rent_in_hold
        p.deposit = data.security_deposit
        p.owner_reserved = data.owner_reserved
      }
      console.log(p, data)
      this.$bvModal.hide('transfer_balance_dialog')
    },



    showAddTenantDialog(pm) {
      this.current_pm = pm

      if (pm.extra_tenant_list && pm.extra_tenant_list.length > 0) {
        this.tenant_list = []
        this.tenant_list.push(...pm.extra_tenant_list)
      } else {
        this.tenant_list = [
          {
            t_payment: pm.t_payment,
            t_institution: pm.t_institution,
            t_transit: pm.t_transfer,
            t_account_number: pm.t_account_number,
            t_bank_account_name: pm.t_bank_account_name,
            t_name: pm.t_name,
            is_extra_tenant: false,
            payment_amount: pm.monthly_rent,
          }
        ]
      }
      this.$bvModal.show('tenant_payment_update_dialog')
    },


    showDespositReleaseDialog(pm) {
      this.current_pm = pm
      // this.$bvModal.show('terminate_dialog')

      this.$router.push({ name: 'pm-property-deposit-release', query: { pm_id: pm.property_id } })
    },

    showTerminateDialog(pm) {
      this.current_pm = pm
      this.$bvModal.show('terminate_dialog')
    },

    showUpdateRentDialog(pm) {

      this.current_pm = pm
      this.$bvModal.show('update_rent_dialog')
    },

    showUpdateCommissionDialog(pm) {

      this.current_pm = pm
      this.$bvModal.show('update_commission_dialog')
    },

    onPMTerminate(data) {
      getPMApi().terminate_pm({ term_date: data.date, pm_id: this.current_pm.pm_id, note: data.note }).then((res) => {
        if (res.errCode == 0) {
          this.$alertify.error("Terminate PM  Successfully");
          this.current_pm.status = 'Terminate'
          this.$bvModal.hide('terminate_dialog')
        } else {
          this.$alertify.error("Terminate PM failed:" + res.errCode);
        }
      })
    },

    onSelectAllPM(evt) {
      this.pmList.map(e => {
        e.checked = evt.target.checked
      })
    },

    onFiltered() {


    },

    onExport() {

    },


    onSearch(txt) {
      if (!txt || txt == '') {
        this.rta_query_type = ''
        this.rta_query_str = ''
        this.currentPage = 1
        this.queryList()
        return;

      }
      this.searched_data = []
      autoComplete({ types: ['PM', 'AGENT'], str: txt }).then(res => {
        if (res.errCode == 0) {
          res.data.map(e => {
            this.searched_data.push({
              str_id: e.str_id,
              s_type: e.s_type,
              obj: e
            })
          })

        }
      })
    },

    onSearchedSelected(e) {

      this.rta_query_type = e.obj.s_type
      this.rta_query_str = e.obj.s_type == 'PM' ? e.obj.property_id : e.obj.id
      this.currentPage = 1
      this.queryList()
    },

  },

  watch: {

    search_str(search_str) {
      this.onSearch(search_str)

    },
  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <b-modal centered id="terminate_dialog" :title="'Terminate RTA -' + current_pm.property_id" size="lg" hide-footer>
      <TerminateDialog :property="current_pm" @confirm="onPMTerminate" @cancel="$bvModal.hide('terminate_dialog')" />
    </b-modal>



    <b-modal centered id="transfer_balance_dialog" :title="'Adjust Balance Distribution -' + current_rent.property_id"
      size="lg" hide-footer>
      <TransferBalanceDialog :property="current_rent" @confirm="onBalanceAdjusted"
        @cancel="$bvModal.hide('transfer_balance_dialog')" />
    </b-modal>


    <b-modal centered id="update_rent_dialog" :title="'Update Property Rent -' + current_pm.property_id"
      title-class="text-center" size="lg" hide-footer>
      <UpdateRentDialog :property="current_pm" @confirm="$bvModal.hide('update_rent_dialog')"
        @cancel="$bvModal.hide('update_rent_dialog')" />
    </b-modal>

    <b-modal centered id="update_commission_dialog" :title="'Update Property Commission -' + current_pm.property_id"
      size="lg" hide-footer>
      <UpdateCommissionDialog :property="current_pm" @confirm="$bvModal.hide('update_commission_dialog')"
        @cancel="$bvModal.hide('update_commission_dialog')" />
    </b-modal>


    <b-modal centered id="export_dialog" :title="'Export RTA Data'" size="lg" hide-footer>
      <ExportDialog :property_list="pmList" @cancel="$bvModal.hide('export_dialog')" />
    </b-modal>

    <b-modal centered id="rta_balance_transfer" :title="'RTA Transfer Balance '" size="lg" hide-footer>
      <RTABalanceTransferDialog @cancel="$bvModal.hide('rta_balance_transfer')"
        @confirm="$bvModal.hide('rta_balance_transfer')" />
    </b-modal>





    <div class="row">


      <div class="col-lg-12">

        <div class="card">
          <div class="card-body">

            <Stat :widgetData="widgetData" />
            <div class="row mb-2">

              <div class="col-md-12 mb-3  me-3">
                <div class="d-flex flex-wrap align-items-start   gap-4">

                  <!-- b-button variant="danger" @click="onMonthClose">Close Month</b-button -->



                  <b-dropdown variant="soft-primary">
                    <template slot="button-content">
                      <i class="fa fa-tag"></i>
                      <span style="margin-left:8px; margin-right: 8px;">Post PM AP</span>
                      <i class="mdi mdi-chevron-down"></i>
                    </template>
                    <b-dropdown-item @click="$router.push({ name: 'pm-property-batch-pm-ap' })">Single Post</b-dropdown-item>
                    <b-dropdown-item @click="$router.push({ name: 'pm-property-batch-pm-ap-upload' })">Upload Template</b-dropdown-item>
                  </b-dropdown>



                  <b-dropdown variant="soft-primary">
                    <template slot="button-content">
                      <i class="fa fa-tag"></i>
                      <span style="margin-left:8px; margin-right: 8px;">Post Expense</span>
                      <i class="mdi mdi-chevron-down"></i>
                    </template>
                    <b-dropdown-item @click="$router.push({ name: 'pm-batch-expense' })">Single Post</b-dropdown-item>
                    <b-dropdown-item @click="$router.push({ name: 'pm-batch-expense-upload' })">Upload Template</b-dropdown-item>
                  </b-dropdown>

                  <b-button class="btn btn-soft-primary"
                    @click="$router.push({ name: 'pm-property-ap-payment' })">Process</b-button>
                  <b-button class="btn btn-soft-primary"
                    @click="$router.push({ name: 'pm-process-agent-post' })">Process Agent
                    Post</b-button>



                  <b-dropdown  text="More" variant="soft-secondary">
                    <template slot="button-content">
                      <i class="fa fa-tag"></i>
                      <span style="margin-left:8px; margin-right: 8px;">More</span>
                      <i class="mdi mdi-chevron-down"></i>
                    </template>

                    <!-- <b-dropdown-item @click="$router.push({name : 'pm-add-landlord-property'})">Setup New PMA</b-dropdown-item> -->
                    <b-dropdown-item @click="$router.push({ name: 'pm-setup-new-rta' })">Setup New RTA</b-dropdown-item>

                    <b-dropdown-item @click="$router.push({ name: 'pm-rent-deposit-pad' })">Batch Deposit PAD
                    </b-dropdown-item>
                    <b-dropdown-item @click="$router.push({ name: 'pm-rent-deposit-cheque' })">Batch Deposit Cheque
                    </b-dropdown-item>
                    <b-dropdown-item @click="$router.push({ name: 'pm-rent-deposit-etransfer' })">Batch Deposit
                      ETransfer
                    </b-dropdown-item>
                    <b-dropdown-item @click="$router.push({ name: 'pm-property-statement-prepare' })">Prepare
                      Statement</b-dropdown-item>
                    <b-dropdown-item v-b-modal.rta_balance_transfer>RTA Balance Transfer</b-dropdown-item>
                    <b-dropdown-item v-b-modal.export_dialog>Export </b-dropdown-item>


                  </b-dropdown>
                  <!-- end dropdown -->
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->




            <div class="row">
              <div class="col-md-3 mb-3">
                <vue-bootstrap-typeahead ref="supplier_name_ref" :styleClass="{ 'form-control': 'form-control' }"
                  v-model="search_str" :data="searched_data"
                  :serializer="s => s.s_type == 'PM' ? 'PM:' + s.str_id : 'Agent:' + s.str_id"
                  placeholder="Search by Agent or PM Code" :foramterDisplay="s => s.str_id"
                  @hit="onSearchedSelected($event)" autocomplete="off" />
              </div>

            </div>
            <div class="row">
              <div class="col-md-2">
                <div class="mb-3 form-floating">
                  <b-form-select v-model="pm_status" class="form-select" id="status_flag"
                    placeholder="No Description"
                    :options="[{ text: 'All', value: '' }, { text: 'Active', value: 'Active' }, { text: 'Terminated', value: 'Terminate' }]" v-on:change="queryList"></b-form-select>
                  <label class="form-control-label" for="status_flag">Status</label>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <div class="table align-middle table-nowrap ">
                <b-table ref="list_tbl_ref" :items="pmList" :fields="fields" responsive="sm" :per-page="totalRows"
                  thead-class="bg-light" :head-variant="'light'" hover
                   :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                   @filtered="onFiltered" class="table-check" style="min-height: 50dvh;">

                  <template #head(C)>
                    <input type="checkbox" class="form-check-input" id="checkAll" @change="onSelectAllPM($event)" />
                  </template>

                  <template #cell(C)="data">
                    <div class="form-check font-size-16">
                      <input type="checkbox" class="form-check-input" :value="data.item.property_id"
                        @change="data.item.checked = $event.target.checked" :checked="data.item.checked" />
                      <label class="form-check-label"></label>
                    </div>
                  </template>

                  <template #cell(RTACode)="data">
                    <router-link :to="{ name: 'pm-overview', query: { pm_id: `${data.item.pm_id}` } }">{{
                      data.item.o_code }}</router-link>
                  </template>
                  <template #cell(Address)="data">
                    {{ data.item.address }}
                  </template>

                  <template #cell(PropertyManager)="data">
                    {{ `${data.item.agent.first_name} ${data.item.agent.last_name}` }}
                  </template>

                  <template #cell(LandLord)="data">
                    <p v-for="(ll, idx) in data.item.landlords" :key="ll.id + '_' + idx">
                      {{ ll.name }}
                    </p>
                  </template>

                  <template #cell(EndDate)="data">
                    {{ data.item.end_date }}
                  </template>
                  <template #cell(MonthlyRent)="data">
                    <!-- p class="text-end"> </p -->
                    ${{ f(data.item.monthly_rent, 2).toLocaleString() }}
                  </template>
                  <template #cell(Balance)="data">

                    ${{ f(data.item.balance_available).toLocaleString() }}

                  </template>
                  <template #cell(RentInHold)="data">

                    ${{ data.item.rent_in_hold.toLocaleString() }}

                  </template>

                  <template #cell(Status)="data">
                    {{ data.item.status }}
                  </template>

                  <template #cell(Action)="data">
                    <b-dropdown variant="white" toggle-class="btn-link text-dark shadow-none">
                      <template v-slot:button-content>
                        <i class="uil uil-ellipsis-h"></i>
                      </template>

                      <b-dropdown-item @click="showAdjustmentBalanceDialog(data.item)">Balance
                        Transfer</b-dropdown-item>
                      <b-dropdown-item @click="showUpdateRentDialog(data.item)">Rent Update</b-dropdown-item>
                      <b-dropdown-item @click="showUpdateCommissionDialog(data.item)">Commission
                        Update</b-dropdown-item>
                      <b-dropdown-item @click="showDespositReleaseDialog(data.item)">RTA Deposit
                        Release</b-dropdown-item>
                      <b-dropdown-item @click="showTerminateDialog(data.item)">RTA Terminate</b-dropdown-item>
                    </b-dropdown>

                  </template>
                </b-table>
              </div>
            </div>
            <!-- end table responsive -->

            <div class="row g-0 text-center text-sm-start">
              <!-- end col -->
              <div class="col-sm-12">
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-end">
                      <!-- pagination -->
                      <b-pagination class="pagination-rounded" v-model="currentPage" :total-rows="totalRows"
                        :per-page="perPage" @change="pageChange"></b-pagination>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->


          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>