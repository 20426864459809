<script>


/**
 * Landlord GST Setup component
 */


//  import {
//   required,
// } from "vuelidate/lib/validators";



import { financial } from '@/api/misc'
import { getPMApi } from '@/api/pm'


export default {

    emits: ['confirm', 'cancel'],

    props: {
        property: {
            type: Object,
            default: () => {

            }
        }
    },
    components: {

    },

    validations: {

    },


    data() {

        return {
            submitted: false,

        }

    },
    methods: {

        f(v, d = 2) {
            financial(v, d)
        },

        formSubmit() {
            this.$v.$touch()

            let data = {
                pm_id: this.property.pm_id,
                rent: this.property.monthly_rent,
                utility_fee: this.property.utility_fee,
                other_income: this.property.other_income,
                parking: this.property.parking
            }
            if (this.$v.$invalid == false) {
                getPMApi().update_rent(data).then(res => {
                    if (res.errCode == 0) {
                        this.$emit('confirm')
                    } else {
                        this.$alertify.error("Update Rent  failed " + res.errCode);
                    }
                })

            }

        },
    },

    created() {

    },

    mounted() {
        console.log(this.property)
    },

    watch: {

    }

};
</script>

<template>

    <form class="needs-validation" @submit.prevent="formSubmit">
        <div class="row">
            <label class="form-label">Regular Rent</label>
        </div>
        <div class="row">

            <div class="col-md-4">
                <div class="mb-3 form-floating">
                    <input class="form-control" id="monthly_rent" v-model="property.monthly_rent" placeholder="rent" />
                    <label for="monthly_rent">Montly Rent</label>
                </div>
            </div>

     


        </div>
        <!-- end row-->


        <div class="row">
            <label class="form-label">Other </label>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="mb-3  form-floating">

                    <input class="form-control" id="parking" v-model="property.parking" placeholder="parking"/>
                    <label for="parking">Parking</label>

                </div>
            </div>

            <div class="col-md-4">
                <div class="mb-3 form-floating">

                    <input class="form-control" id="utility_fee" v-model="property.utility_fee" placeholder="utility"/>
                    <label for="utility_fee">Utilities</label>

                </div>
            </div>

            <div class="col-md-4">
                <div class="mb-3 form-floating">

                    <input class="form-control" id="other_income" v-model="property.other_income" placeholder="other" />
                    <label for="other_income">Other</label>

                </div>
            </div>
        </div>
        <!-- end row-->

        <hr>
        <div class="row">
            <div class="col-md-12">
                <div class="d-flex flex-wrap gap-2  justify-content-end ">
                    <b-button variant="soft-light" type="button" @click="$emit('cancel')">Cancel</b-button>
                    <b-button variant="primary" type="submit">Save</b-button>
                </div>
            </div>
           

        </div>
        <!-- end row-->


    </form>

</template>