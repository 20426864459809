<script>


/**
 * Landlord GST Setup component
 */


//  import {
//   required,
// } from "vuelidate/lib/validators";



import { financial } from '@/api/misc'
import { getPMApi } from '@/api/pm'


export default {

    emits: ['confirm', 'cancel'],

    props: {
        property: {
            type: Object,
            default: () => {

            }
        }
    },
    components: {

    },

    validations: {

    },

    computed: {
        total_balance_currency: function() {
            return '$' +this.total_balance.toLocaleString();
        }
    },

    data() {

        return {
            submitted: false,
            owner_reserved: '',
            security_deposit: '',
            available_balance: '',
            total_balance: 0,
            rent_in_hold: 0,
        }

    },
    methods: {
        formSubmit() {
            this.$v.$touch()

            if (this.$v.$invalid == false) {
                getPMApi().transfer_balance({ pm_id: this.property.property_id, rent_in_hold: this.rent_in_hold, security_deposit: this.security_deposit, owner_reserved: this.owner_reserved }).then(res => {
                    if (res.errCode == 0) {
                        this.$emit('confirm', { pm_id: this.property.property_id, rent_in_hold: this.rent_in_hold, new_balance: this.available_balance, security_deposit: this.security_deposit, owner_reserved: this.owner_reserved })
                    } else {
                        this.$alertify.error("Update Balance Hold failed " + res.errCode);
                    }
                })

            }

        },
    },

    created() {

    },

    mounted() {

        this.available_balance = financial(Number(this.property.available_balance || 0), 2)
        this.owner_reserved = financial(Number(this.property.owner_reserved || 0), 2)
        this.security_deposit = financial(Number(this.property.deposit || 0), 2)
        this.total_balance = financial(Number(this.property.balance_in_trust || 0), 2)
    },

    watch: {
        owner_reserved(new_val) {
            let new_owner_reserved = financial(Number(new_val), 2)
            this.available_balance = financial(this.total_balance - this.security_deposit - new_owner_reserved, 2)

        },

        security_deposit(new_val) {
            let new_security_deposit = financial(Number(new_val), 2)
            this.available_balance = financial(this.total_balance - this.owner_reserved - new_security_deposit, 2)
        }
    }

};
</script>

<template>

    <form class="needs-validation" @submit.prevent="formSubmit">
       


        <div class="row">
            <label class="form-label">Adjustable Balance </label>
        </div>

        <div class="row">

            <div class="col-md-4">
                <div class="mb-3 form-floating">

                    <input class="form-control" id="available_balance" v-model="available_balance" readonly />
                    <label for="available_balance">Available Balance</label>
                </div>
            </div>
            <div class="col-md-4">
                <div class="mb-3 form-floating">
                    <input class="form-control" id="security_deposit" v-model="security_deposit" placeholder="Security Deposit"  />
                    <label for="security_deposit">Security Despoit</label>
                </div>
            </div>

            <div class="col-md-4">
                <div class="mb-3 form-floating">
                    <input class="form-control"  id="owner_reserved" v-model="owner_reserved" placeholder="Owner Reserved"  />
                    <label for="owner_reserved">Owner Reserved</label>
                </div>
            </div>


        </div>
        <!-- end row-->

        <div class="row mt-3">
            <label class="form-label">Total Balance:  </label>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="mb-3 ">
                    <input class="form-control"   v-model="total_balance_currency" placeholder="Total Balance" readonly />

                </div>
            </div>
        </div>

      
        <hr>
        <div class="row">
            <div class="col-md-12">
                <div class="d-flex flex-wrap gap-2  justify-content-end ">
                    <b-button  variant="soft-light" type="button" @click="$emit('cancel')">Cancel</b-button>
                    <b-button variant="primary" type="submit">Save</b-button>
                   
                </div>
            </div>

        </div>
        <!-- end row-->


    </form>

</template>