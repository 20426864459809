<script>


/**
 * Terminate PM Diloag
 */

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import {
    required,
} from "vuelidate/lib/validators";





export default {

    emits: ['confirm', 'cancel'],

    props: {
        property: {
            type: Object,
            default: () => {

            }
        }
    },

    components: {
        flatPickr
    },

    validations: {
        date: { required }
    },


    data() {

        return {
            date: '',
            note: '',
            submitted: false,
        }

    },
    methods: {
        formSubmit() {
            this.$v.$touch()

            if (this.$v.$invalid == false) {
                let data = {
                    property_id: this.property.property_id,
                    note: this.note,
                    date: this.date
                }
                this.$emit('confirm', data)
            }

        },
    },

    created() {

    },

    mounted() {

    }

};
</script>

<template>
    <form class="needs-validation" @submit.prevent="formSubmit">

        <div class="row">
            <label class="form-label">Terminattion Date </label>
        </div>

        <div class="row">

            <div class="col-md-6">
                <div class="mb-3  form-floating">
                    
                    <flat-pickr v-model="date" placeholder="Select a date" class="form-control" :class="{
                        'is-invalid': $v.date.$error,
                    }"></flat-pickr>
                    <label for="validationCustom02">Pick a Date</label>
                    <div v-if="$v.date.$error" class="invalid-feedback">
                        <span v-if="!$v.date.required">This value is required.</span>
                    </div>
                </div>
            </div>

        </div>
        <!-- end row-->

        <div class="row">
            <label class="form-label">Note </label>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="mb-3  form-floating">
                  
                    <input v-model="note" placeholder="type node" id="note" class="form-control" maxlength="30"/>
                    <label for="node">Type Note</label>
                </div>
            </div>
        </div>
        <!-- end row-->


        <hr>
        <div class="row">
            <div class="col-md-12">
                <div class="d-flex flex-wrap gap-2  justify-content-end ">
                    <b-button variant="soft-light" type="button" @click="$emit('cancel')">Cancel</b-button>
                    <b-button variant="primary" type="submit">Submit</b-button>
                </div>
            </div>


        </div>
        <!-- end row-->


    </form>

</template>