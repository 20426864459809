<script>



import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { getPMApi } from '@/api/pm'

import {
    required,
} from "vuelidate/lib/validators";



export default {

    emits: ['confirm', 'cancel'],

    props: {
        property_list: {
            type: Array,
            default: () => []
        }
    },
    components: {
        flatPickr
    },

    validations() {
        if (this.export_type == 'Statement') {
            if (this.is_latest == true) {
                return {
                    start_date: {},
                    end_date: { required }
                }
            } else {
                return {
                    start_date: { required },
                    end_date: { required }
                }
            }

        } else {
            return {
                start_date: {},
                end_date: {},
            }
        }
    },


    data() {

        return {
            export_type: 'EndingBalance',
            start_date: '',
            end_date: '',
            is_exporting: false,
            export_failed: false,
            job_id: '',
            is_latest: true,
            all_flag: false,
        }

    },
    methods: {
        onExportPdf() {

            this.$v.$touch()
            if (this.$v.$invalid == false) {
                let selected_list = []
                let colums = {
                    keys: ['address', 't_payment', 'balance_in_trust'],
                    labels: ['Address', 'Tenant Payment', 'Ending Balance']
                }

                this.property_list.map(e => e.checked == true ? selected_list.push(e.pm_id) : '')
                let data = {
                    export_type: this.export_type + "_Pdf",
                    property_list: selected_list,
                    columns: colums,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    all_flag: this.all_flag
                }


                if (this.export_type == 'Statement') {
                    this.is_exporting = true
                    getPMApi().export_statement_list(data).then(res => {
                        if (res.errCode != 0) {
                            this.is_exporting = false
                            this.$alertify.error(" Export failed:" + res.errCode);
                        } else {
                            this.job_id = res.data.job.job_id
                            this.task_id = setInterval(this.queryProgress, 2000)
                        }
                    })
                } else {
                    getPMApi().export_pm(data).then(res => {
                        if (res.errCode == 0) {
                            window.open(res.url, '_blank')
                            this.is_exporting = false
                        }
                    })
                }

            }
        },

        onExportExcel() {

            this.$v.$touch()
            if (this.$v.$invalid == false) {
                let selected_list = []
                let colums = {
                    keys: ['address', 't_payment', 'balance_in_trust'],
                    labels: ['Address', 'Tenant Payment', 'Ending Balance']
                }

                this.property_list.map(e => e.checked == true ? selected_list.push(e.pm_id) : '')
                let data = {
                    export_type: this.export_type + "_Excel",
                    property_list: selected_list,
                    columns: colums,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    all_flag: this.all_flag
                }
                getPMApi().export_pm(data).then(res => {
                    if (res.errCode == 0) {
                        window.open(res.url, '_blank')
                        this.is_exporting = false
                    }
                })
            }
        },

        queryProgress() {
            getPMApi().query_export_job_status({ job_id: this.job_id }).then(res => {
                if (res.errCode == 0) {
                    if (res.data.state == 'FINISHED') {
                        clearInterval(this.task_id)
                        window.open(res.data.url, '_blank')
                        this.is_exporting = false
                    }
                } else {
                    clearInterval(this.task_id)
                    this.export_failed = true
                    this.is_exporting = false
                }
            })
        }

    },

    created() {

    },

    mounted() {

    },

    destroyed() {
        clearInterval(this.task_id)
    }

};
</script>

<template>

    <form class="needs-validation" @submit.prevent="formSubmit">

        <div class="row">
            <label class="form-label">Export Selection </label>
        </div>

        <div class="row">

            <div class="col-md-4">
                <div class="mb-3 form-floating">
                    <b-select v-model="export_type" class="form-select" id="export_type">
                        <option value="Statement">Statement</option>
                        <option value="EndingBalance" selected>Ending Balance</option>
                    </b-select>
                    <label for="export_type">Export Type</label>
                </div>
            </div>
        </div>
        <div class="row">

            <div class="col-md-3">
                <div class="mb-1 gap-1 d-flex">

                    <input type="checkbox" id="all_flag" class="form-check-input" v-model="all_flag" />
                    <label for="all_flag">ALL RTAs</label>
                </div>
            </div>



        </div>
        <!-- end row-->

        <div class="row">

            <div class="col-md-3" v-if="export_type == 'EndingBalance'">
                <div class="mb-3 gap-1 d-flex">

                    <input type="checkbox" id="ending_balance_flag" class="form-check-input" :checked="true"
                        v-model="is_latest" />
                    <label for="ending_balance_flag">Lastest Ending Balance</label>
                </div>
            </div>

        </div>
        <!-- end row-->


        <div class="row" v-if="export_type == 'Statement' || is_latest == false">
            <label class="form-label">Date Selection </label>
        </div>

        <div class="row" v-if="export_type == 'Statement' || is_latest == false">

            <div class="col-md-4" v-if="export_type == 'Statement'">
                <div class="mb-3  form-floating">

                    <flat-pickr v-model="start_date" placeholder="Select a date" class="form-control" id="start_date"
                        :class="{ 'is-invalid': $v.start_date.$error }"></flat-pickr>
                    <label for="start_date">Start Date</label>
                    <div v-if="$v.start_date.$error" class="invalid-feedback">
                        <span v-if="!$v.start_date.required">This value is required.</span>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="mb-3  form-floating">

                    <flat-pickr v-model="end_date" placeholder="Select a date" class="form-control" id="end_date"
                        :class="{ 'is-invalid': $v.end_date.$error }"></flat-pickr>
                    <label for="end_date">End Date</label>
                    <div v-if="$v.end_date.$error" class="invalid-feedback">
                        <span v-if="!$v.end_date.required">This value is required.</span>
                    </div>
                </div>
            </div>
        </div>
        <!-- end row-->

        <div class="row mb-3" v-if="is_exporting">
            <b-alert show variant="danger">
                <b-spinner variant="danger" label="Spinning" class="me-2"></b-spinner>
                Exporting, please waiting.....
            </b-alert>
        </div>

        <div class="row mb-3" v-if="export_failed">
            <b-alert show variant="danger">
                Export Failed, please retry
            </b-alert>
        </div>



        <hr>
        <div class="row">
            <div class="col-md-12">
                <div class="d-flex flex-wrap gap-2  justify-content-end ">
                    <b-button variant="soft-light"  @click="$emit('cancel')">Close</b-button>

                    <b-button variant="success" type="button" @click="onExportExcel" :disabled="is_exporting">Export
                        Excel</b-button>

                    <b-button variant="primary" type="button" @click="onExportPdf" :disabled="is_exporting">Export
                        PDF</b-button>
                </div>
            </div>

        </div>
        <!-- end row-->


    </form>

</template>